/** Cards */
.card_info {
  border: 1px solid #075e97;
  border-radius: 10px;

  .ant-card-head-title {
    font: normal normal normal 15px Montserrat-Regular;
    color: #848484;
  }
}
