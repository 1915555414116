//Notification success
.ntf_success {
  border-radius: 8px;
  background-color: rgba(229, 247, 240, 0.9);
  padding: 12px;
  border-left: 7px solid #32b088;

  .ant-notification-notice-message {
    font-weight: 600;
    color: #32b088;
    font-size: 14px;
  }

  .ant-notification-notice-description {
    font-weight: 300;
    color: #3bb38d;
    font-size: 12px;
  }

  .ant-notification-close-icon,
  .ant-notification-notice-icon {
    color: #32b088;
  }
}

//Notification error
.ntf_error {
  border-radius: 8px;
  background-color: rgba(253, 232, 231, 0.9);
  padding: 12px;
  border-left: 7px solid #fd6666;

  .ant-notification-notice-message {
    font-weight: 600;
    color: #fd6666;
    font-size: 14px;
  }

  .ant-notification-notice-description {
    font-weight: 300;
    color: #fd6869;
    font-size: 12px;
  }

  .ant-notification-close-icon,
  .ant-notification-notice-icon {
    color: #fd6666;
  }
}

//Notification Info
.ntf_info {
  border-radius: 8px;
  background-color: rgba(233, 233, 254, 0.9);
  padding: 12px;
  border-left: 7px solid #6a6df2;

  .ant-notification-notice-message {
    font-weight: 600;
    color: #6a6df2;
    font-size: 14px;
  }

  .ant-notification-notice-description {
    font-weight: 300;
    color: #888bfe;
    font-size: 12px;
  }

  .ant-notification-close-icon,
  .ant-notification-notice-icon {
    color: #6a6df2;
  }
}

//Notification Warn
.ntf_warn {
  border-radius: 8px;
  background-color: rgba(253, 242, 228, 0.9);
  padding: 12px;
  border-left: 7px solid #d0943a;

  .ant-notification-notice-message {
    font-weight: 600;
    color: #d0943a;
    font-size: 14px;
  }

  .ant-notification-notice-description {
    font-weight: 300;
    color: #d0963e;
    font-size: 12px;
  }

  .ant-notification-close-icon,
  .ant-notification-notice-icon {
    color: #d0943a;
  }
}
