/** Label */
.flex-label.ant-form-item-label > label {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .blue-label {
    color: #33b6d4;
  }
}

.page-subtitle {
  font: normal normal bold 25px/37px Montserrat-Bold;
}
