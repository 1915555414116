.text-center {
  text-align: center;
}

.text-end {
  text-align:end;
}

.text-bold {
  font-weight: bold;
  font-family: Montserrat-Bold;
}
