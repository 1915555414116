/**  Custom buttons properties  */

.btn-blue,
.btn-blue:hover,
.btn-blue:active,
.btn-blue:focus {
  width: 170px;
  background-color: @primary-blue;
  color: #ffffff;
  border-radius: 10px;
  border: none;
}

.btn-blue-default,
.btn-blue-default:focus,
.btn-blue-default:active,
.btn-blue-default:hover {
  width: 170px;
  background-color: #ffffff;
  color: @primary-blue;
  border-radius: 10px;
  border-color: @primary-blue;
}

.btn-default-size,
.btn-default-size:focus,
.btn-default-size:active,
.btn-default-size:hover {
  background-color: #ffffff;
  color: @primary-blue;
  border-radius: 10px;
  border-color: @primary-blue;
}

.btn-gray-default,
.btn-gray-default:focus,
.btn-gray-default:active,
.btn-gray-default:hover {
  width: 170px;
  background-color: #ffffff;
  color: #a9a9a9;
  border-radius: 10px;
  border-color: #a9a9a9;
}

.btn-gray,
.btn-gray:focus,
.btn-gray:active,
.btn-gray:hover {
  width: 170px;
  background-color: #a9a9a9;
  color: #ffffff;
  border-radius: 10px;
  border-color: #a9a9a9;
}

.btn-red,
.btn-red:focus,
.btn-red:active,
.btn-red:hover {
  width: 170px;
  background-color: #de374b;
  color: #ffffff;
  border-radius: 10px;
  border-color: #de374b;
}

.btn-white,
.btn-white:focus,
.btn-white:active,
.btn-white:hover {
  width: 170px;
  background-color: #FFFFFF;
  color: #00AECC;
  border-radius: 10px;
  border-color: #00AECC;
  font-family: Montserrat-SemiBold;
}

.btn-transparent,
.btn-transparent:focus,
.btn-transparent:active,
.btn-transparent:hover {
  width: 170px;
  background-color: transparent;
  color: #FFFFFF;
  border-radius: 10px;
  border-color: #FFFFFF;
  font-family: Montserrat-SemiBold;
}

.w-btn-fc,
.w-btn-fc:focus,
.w-btn-fc:active,
.w-btn-fc:hover {
  width: fit-content;
}

.rounder,
.rounder:focus,
.rounder:active,
.rounder:hover {
  width: 170px;
  border-radius: 70px !important;
}

.bordered,
.bordered:focus,
.bordered:active,
.bordered:hover {
  border-color: #ffffff;
}
