@import 'ui.less';

body {
  height: 100vh;
  font-family: 'Montserrat-Regular';
}

/** Main Layout */

.logo-admin {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 70px;
  background: #075e97;
  .img_logo {
    width: 90px;
  }
}

.img_logo_sm {
  width: 57px;
}

[nz-row] {
  margin: 10px 0;
}

.input-plate{
  text-transform: uppercase;
}