/** Inputs */
.ant-picker,
.ant-input,
.ant-input-affix-wrapper,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px;
  border-color: #a9a9a99c;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 4px;
  font: normal normal normal 12px Montserrat-Regular;
}

.ant-form-item-explain-error{
  font-size: 10px;
}