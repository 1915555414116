/** custom menu dropdowns */

.menu_dropdown.ant-dropdown-menu {
  border-radius: 10px;
  padding: 6px 4px;

  .menu_title {
    padding: 4px 8px;
    font: normal normal 600 15px Montserrat-Regular;
    color: #b0b0b0;
  }

  .menu_item {
    margin: 10px;
    background-color: #f8f8f8;
    border-radius: 10px;
    font: normal normal 500 16px Montserrat-Regular;
    color: #b0b0b0;
    padding: 7px 15px;
  }
}
